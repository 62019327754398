import React from 'react';
import DocumentationSection from '../../containers/SaasModern/Documentation';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import example_carousel from '../../../../common/src/assets/image/2019/example_carousel.png';
import bold_titles from '../../../../common/src/assets/image/whatsNew/bold-titles.png';
import example_1_day_no_overlay from '../../../../common/src/assets/image/example_1_day_no_overlay.png';
import theme_list from '../../../../common/src/assets/image/whatsNew/theme-list.png';
import month_default_theme from '../../../../common/src/assets/image/whatsNew/month-default-theme.png';
import month_main_event_theme from '../../../../common/src/assets/image/whatsNew/month-main-event-theme.png';
import paper_size from '../../../../common/src/assets/image/whatsNew/paper-size.png';
import multiweek_first_day from '../../../../common/src/assets/image/whatsNew/multiweek-first-day.png';
import use_outlook_category_colors from '../../../../common/src/assets/image/whatsNew/use-outlook-category-colors.png';
import Form_Letter from '../../../../common/src/assets/image/whatsNew/Form-Letter.png';
import High_Contrast_Month_Template from '../../../../common/src/assets/image/whatsNew/High-Contrast-Month-Template.png';
import Start_Output_Month from '../../../../common/src/assets/image/whatsNew/Start-Output-Month.png';
import Agenda_Template_Show_Ending_Times_And_Locations from '../../../../common/src/assets/image/whatsNew/Agenda-Template-Show-Ending-Times-And-Locations.png';
import Weekday_Template from '../../../../common/src/assets/image/whatsNew/Weekday-Template.png';
import Month_Overflow_Option from '../../../../common/src/assets/image/whatsNew/Month-Overflow-Option.png';
import Add_To_Favorites from '../../../../common/src/assets/image/whatsNew/Add-To-Favorites.png';
import WeeklyTemplateWithColorSidebars from '../../../../common/src/assets/image/example_week_columns_with_color_sidebars.png';
import timeslot_10_minute from '../../../../common/src/assets/image/whatsNew/timeslot-10-minute.png';
import item_template_calendar_name from '../../../../common/src/assets/image/whatsNew/item-template-calendar-name.png';
import agenda_color_item_titles from '../../../../common/src/assets/image/whatsNew/agenda-color-item-titles.png';
import filter_include_exclude_titles from '../../../../common/src/assets/image/whatsNew/filter-include-exclude-titles.png';
import filter_include_exclude_categories from '../../../../common/src/assets/image/whatsNew/filter-include-exclude-categories.png';

const Documentation = () => {
  const content = (
    <div>
      <h3>Version 2023.01 - August 2, 2023</h3>
      <ul>
        <li>Improved the Item Template to provide an option for showing time zones.</li>
        <li>Improved the Item Template to allow new lines to be specified.</li>
        <li>Improved generating calendars in Word to better fit on the page. In some cases, generated calendars wouldn't fit on the page and would be clipped.</li>
        <li>Corrected various problems adding calendars and importing events from Microsoft 365. In some cases, calendars weren't shown on the Add Calendar window, or events weren't included in the generated output.</li>
        <li>Corrected filtering of items. Selecting the option to exclude items containing specified text wasn't working, and the items would still appear in the output.</li>
        <li>Corrected importing from Todoist to use the latest API. The previous API is deprecated and no longer accessible, so importing from Todoist wasn't working.</li>
        <li>Corrected problems with Excel and CSV calendar data sources sometimes being removed and needing to be re-added.</li>
        <li>Corrected importing of custom fields from Excel and CSV calendar data sources to not show the custom field labels in the description text if the custom field values are blank.</li>
        <li>Corrected mapping of column names in Excel and CSV calendar data sources to ignore leading or trailing whitespace.</li>
        <li>Corrected multi-day items imported from Excel and CSV calendar data sources. The items shown in the generated output were ending a day earlier than specified in the input file.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2022.01 - February 13, 2022</h3>
      <ul>
        <li>Added a "More Options..." button to Step 1 of the Create Calendar panel, to make it more apparent that additional options can be accessed.</li>
        <li>Improved the calendar settings user interface on Step 3 of the Create Calendar panel to not require scrolling to see all buttons if the calendar name is short enough to fit.</li>
        <li>Improved processing iCalendar-based calendar sources. Resource values will be shown as locations in the output, if the location value is blank in the calendar data source.</li>
        <li>Corrected the date format of some templates to show the correct date format based on the Windows regional settings.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2021.04 - December 5, 2021</h3>
      <ul>
        <li>Corrected to not show HTML tags in the output of certain templates (such as the 1 Day/Page template) if the setting for showing item times and/or titles in bold has been selected.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2021.03 - November 26, 2021</h3>
      <ul>
        <li>Added an option to the various week column templates for showing item titles in bold, regardless of whether description text is also included.</li>
        <li>Added an option to some of the additional week column templates for showing item times in bold.</li>
        <li>Improved to not require administrator consent before adding calendars from Office 365 / Microsoft 365.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2021.02 - October 12, 2021</h3>
      <ul>
        <li>Added an option to the various week column templates for showing item times in bold.</li>
        <li>Added the ability to import category names and colors from a Microsoft 365 account.</li>
        <li>Corrected calendar generation in Word. In some cases, the output page orientation was incorrect or the generated calendar was clipped by the page edges, especially for calendar templates that can span multiple pages.</li>
        <li>Corrected a problem with the window for adding local Outlook calendars sometimes not listing all available calendars, especially when using multiple account profiles.</li>
        <li>Corrected a problem on some computers where the window for adding Microsoft 365 calendars could get stuck on loading.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2021.01 - June 6, 2021</h3>
      <ul>
        <li>Added filtering options for either including or excluding items based on associated category. Previously, items that had a matching category would be excluded from the output. Now the filter can be configured so items that DO NOT have a matching category will be excluded instead.<br/>
          <Image alt="" src={filter_include_exclude_categories} width="455" />
        </li>
        <li>Added filtering options for including or excluding items based on words or phrases in their titles.<br/>
          <Image alt="" src={filter_include_exclude_titles} width="455" />
        </li>
        <li>Added an option to the Agenda template for placing the name of the associated calendar data source below the event/task titles.</li>
        <li>Added an option to the Agenda template for coloring event/task title text based on the color of the associated calendar data source (or category).<br/>
          <Image alt="" src={agenda_color_item_titles} width="452" />
        </li>
        <li>Added [Calendar Name] as an available field in the item template that can be specified in the PrintableCal Options. When this field is in the item template, the associated calendar name will be included in event and task titles that are in the generated output of most templates.<br/>
          <Image alt="" src={item_template_calendar_name} width="891" />
        </li>
        <li>Improved the presets that can be loaded or saved on Step 3 of the Create Calendar panel. The presets will now include the filtering criteria. In other words, the presets can now be used to save and load different combinations of filters and/or calendar data sources.</li>
        <li>Improved the category filtering user interface. Instead of needing to type the full category name and separate multiple categories with semicolons, a drop-down list will appear with matching suggestions while typing a category name, and categories that have been added to the filter can be removed by clicking an X button on the category.</li>
        <li>Improved all templates that show time slots (such as the Day and Week Column templates) to include a setting for outputting 10 minute time slots. Previously, the smallest supported time slot was 15 minutes.<br/>
          <Image alt="" src={timeslot_10_minute} width="450" />
        </li>
        <li>Improved the Week Grid template to support specifying start and end cell names where event titles should appear on each day, instead of assuming event titles always start on the row immediately below the day name, and end on the row immediately above the next day name.</li>
        <li>Corrected problems importing from iCloud or other iCalendar URLs/files. In some cases, an error message would appear (expecting COLON, found ' ') and no items would appear in the output.</li>
        <li>Corrected the Calendar Prefix to not appear twice if the item template has been modified (in the Options) to show calendar prefixes.</li>
        <li>Corrected the instructions for connecting to Yahoo! Calendar to be consistent with the UI on the current Yahoo! Calendar web page.</li>
        <li>Corrected an error loading PrintableCal on computers where the FIPS algorithm policy is enabled.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.12 - October 12, 2020</h3>
      <ul>
        <li>Added the ability to map any number of custom fields in the Excel and CSV calendar data sources. When mapped, values in those fields will appear in the item descriptions/details when using a template that supports showing additional details.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.11 - September 17, 2020</h3>
      <ul>
        <li>Corrected the window for adding Microsoft 365 or Office 365 calendars to show all available calendars.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.10 - September 3, 2020</h3>
      <ul>
        <li>Corrected an error message that could appear on some computers when attempting to add Microsoft 365 or Office 365 calendars.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.09 - August 16, 2020</h3>
      <ul>
        <li>Corrected the Gantt Chart template to use custom event colors or category colors, when enabled.</li>
        <li>Corrected intermittent error messages that could appear on some computers when opening or closing documents.</li>
        <li>Corrected cases where the progress bar could continue to display after calendar creation has completed.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.08 - May 29, 2020</h3>
      <ul>
        <li>Added a Basic version. This version is more affordable than the Standard version, but is limited to only including data associated with one calendar data source in the generated output.</li>
        <li>Added the ability to import from any calendar data source (Outlook, Exchange Calendar, Todoist, etc.) in the Basic version and up. Previously, the ability to import from certain calendar data source, such as Outlook, was limited to only higher versions.</li>
        <li>Added the ability to import calendar data from Microsoft 365. This includes Office 365, Windows 10, and Exchange Online calendars, task plans, and to-do lists.</li>
        <li>Added the ability to import filters and labels as calendar data sources from Todoist.</li>
        <li>Improved Word and PowerPoint output when using the Excel Workbook or Picture output format. The generated output will span multiple pages when necessary, rather than being shrunk to fit on a single page and becoming too small to read.</li>
        <li>Corrected an error message that could appear on some computers when generating a calendar that include events defined in local Outlook calendars.</li>
        <li>Corrected cases where non all-day events that end at 12:00 AM could also appear on the next day.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.07 - February 26, 2020</h3>
      <ul>
        <li>Added a weekly column template that shows color-coded sidebars to the left of item titles/descriptions. This template optimizes the amount of space available for item text. It also reduces colored ink usage when printing.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <p>
        <Image alt="" src={WeeklyTemplateWithColorSidebars} />
      </p>
        <h3>Version 2020.06 - February 22, 2020</h3>
      <ul>
        <li>Improved all templates that show items within time-slots (such as the Week Column template) to use the border colors and widths that are specified for the associated calendar or category appearances. Previously, some borders wouldn't appear or would be the wrong color and thickness.</li>
        <li>Improved to import meeting attendees from Outlook calendars and all iCalendar-based sources. The meeting attendee names and email addresses can appear in the output of supported templates, such as the List template.</li>
        <li>Corrected the font name selection for calendar data source and category appearances. The listed font names will now match the same fonts that are available in Excel, Word, and PowerPoint.</li>
        <li>Corrected appearances of generated items to use the font that was selected in the associated appearance settings. Previously, some selected fonts weren't being correctly applied to the output.</li>
        <li>Corrected calendar data source and category appearance settings to save whether bold, italic, and underline were selected for the font style. Previously, those selections would be lost upon exit and would need to be reselected the next time Excel, Word, or PowerPoint was started.</li>
        <li>Corrected the Gantt Chart and Form Letter templates to always use date range that is specified in the template settings when generating.</li>
        <li>Corrected the 1 Day/Page with Week Grid template to not override the background colors of events and tasks that occur on today's date.</li>
        <li>Many other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.05 - February 9, 2020</h3>
      <ul>
        <li>Corrected the 1 Day/Page without Overlay template to show events that have start times.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.04 - February 1, 2020</h3>
      <ul>
        <li>Improved the List templates to support a "Category" column for displaying associated categories. Also updated the default List templates to include this column.</li>
        <li>Improved the options for using Outlook and Exchange Server category colors to better integrate with any category colors defined in the PrintableCal options.</li>
        <li>Corrected the Help button on the Options window and various other links to point to the correct pages on the PrintableCal website.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.03 - January 26, 2020</h3>
      <ul>
        <li>Improved performance when generating calendars in Excel. The Excel window will no longer flicker or update while calendar generation is still in progress.</li>
        <li>Improved importing from Outlook. If PrintableCal is unable to find a previously added calendar folder, a window will appear where the calendar can be selected before continuing, rather than an error message appearing.</li>
        <li>Corrected cases where duplicate events could appear in the output.</li>
        <li>Corrected an error message that could appear when generating calendars in Excel on some computers.</li>
        <li>Corrected cases where event cells were not properly merged in templates that show events in time slot rows. </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.02 - January 7, 2020</h3>
      <ul>
        <li>Added a day template with time slots that are split between the left and right sides of the page.</li>
        <li>Added an Outlook 2003 month template. This template matches the monthly print style of Outlook 2003.</li>
        <li>Added a version of the 2 Weeks/Page template which can fit up to 10 events/tasks per day.</li>
        <li>Corrected the week grid templates to not color the background of multi-day events that have start/end times if the "Only use background color for all-day items" template setting is selected.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2020.01 - January 2, 2020</h3>
      <ul>
        <li>Added 31 new templates, including new layouts, templates that make better use of Office color themes, and templates that include output for newly added features. See the <Link to="/Whats-New-Version-2020">PrintableCal 2020 release notes</Link> for more details and example screenshots.</li>
        <li>Added year template layouts: 2 Years/Page, 3 Years/Page, 4 Years/Page, Months as Columns, and Months as Rows.</li>
        <li>Added week template layouts: Columns with Task List and Grid with Task List.</li>
        <li>Improved the 12 Months/Page Template layout to support an optional list of events, tasks, and notes.</li>
        <li>Improved the Year Template layout to support week numbers.</li>
        <li>Improved Excel, Word, and PowerPoint startup speed by only loading PrintableCal on demand instead of on startup.</li>
        <li>Improved template selection to show an example preview when hovering the mouse over a template's name.</li>
        <li>Improved the user interface for entering numeric values (such as the year and number of rows) in template settings.</li>
        <li>Improved templates to support inclusion of 2 separate mini calendars, such as for the previous month and next month.</li>
        <li>Corrected templates to properly fit the page size.</li>
        <li>Corrected cases where additional unnecessary white space could be shown when item text is wrapped.</li>
        <li>Corrected positioning problems of overlapping events in templates that include timeslot columns.</li>
        <li>Corrected alignment of calendar legends and mini calendars to match the text alignment of the target cell.</li>
        <li>Corrected an intermittent message during calendar generation asking to confirm deletion of temporary worksheets.</li>
        <li>Many other corrections and improvements.</li>
      </ul>
      <h3>Version 2019.13 - December 7, 2019</h3>
      <ul>
        <li>Improved the calendar template list user interface.</li>
        <li>
          Improved adding Excel and CSV file data sources. The default calendar
          data source name will match the file name including the file
          extension.
        </li>
        <li>
          Improved calendar data sources to include a hover tooltip showing
          details about the data source location, such as the full URL or file
          path, associated Google account, etc.
        </li>
        <li>
          Corrected importing events that are not specifically marked as all
          day, but begin at 12:00 AM on one day and end at 12:00 AM on another
          day. Those events will be treated as being all-day and won't
          incorrectly appear to also occur on the ending date.
        </li>
        <li>
          Corrected cases where the About PrintableCal window can appear behind
          the main Excel, Word, or PowerPoint window.
        </li>
        <li>
          Corrected selecting the time slot range for various templates, such as
          the Day template, when an ending time slot of midnight is selected.
          Previously, this would result in the starting time slot also being set
          to midnight.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2019.12 - November 27, 2019</h3>
      <ul>
        <li>
          Updated to use the latest Todoist v8 API. Importing from Todoist
          stopped working due to an older API being used.
        </li>
        <li>
          Corrected various cases where the bottom edges of events could
          sometimes be clipped in the Month template as well as some of the
          other templates.
        </li>
        <li>
          Corrected the Month template when configured with the option to
          overflow items that can't fit to additional pages. Some items would
          get clipped on the first page instead of overflowing to the additional
          page.
        </li>
        <li>
          Corrected a "name already exists" warning message. This was usually
          seen when generating to the same workbook where a calendar was
          previous generated, but could also be seen under other conditions.
        </li>
        <li>
          Corrected the Week Grid template. When multiple calendar data sources
          were selected, some events wouldn't appear in the output.
        </li>
        <li>
          Corrected the "More items" tooltip to not show HTML formatting code.
          This was a problem in the Month template and other templates that show
          a "More items" indicator when items can't fit.
        </li>
        <li>
          Corrected cases where templates could be resized smaller than required
          for fitting on a single page.
        </li>
        <li>
          Corrected the Gantt Chart and Form Letter templates to use the date
          range specified in the template settings.
        </li>
        <li>Many other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2019.11 - November 3, 2019</h3>
      <ul>
        <li>
          Improved word-wrapping in the Month template and many other templates.
          Previously, unnecessary empty space could sometimes appear below event
          titles that were word-wrapped. That will no longer happen.
        </li>
        <li>
          Improved the Multiple Months as Columns template to allow specifying a
          minimum of 2 rows per day.
        </li>
        <li>
          Corrected the Multiple Months as Columns and Multiple Months as Rows
          templates to include events that span more than 1 day.
        </li>
        <li>
          Corrected importing from Exchange Server when a large date range is
          specified or when a large number of events are defined on Exchange
          Server.
        </li>
        <li>
          Corrected importing tasks from Google. The tasks could be imported to
          the wrong day, depending on the user's time zone.
        </li>
        <li>
          Corrected importing from Excel or CSV files when there's only a Date
          column and no column for specifying event durations or times. The
          imported events would be one day off.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2019.10 - September 25, 2019</h3>
      <ul>
        <li>Added the ability to import tasks from Exchange Server.</li>
        <li>
          Added a "Restore Defaults" button to all template settings. Clicking
          this button will restore the selected template's settings back to the
          original factory defaults.
        </li>
        <li>
          Added a Google Calendar option to use either the modern or classic
          color sets. Previously, the modern color set was always used, so if
          Google Calendar was configured with the classic color set, the text
          and background colors of PrintableCal's output wouldn't match the
          colors seen in Google Calendar. To access this option, click the
          Options button on the PrintableCal tab of the ribbon and then click
          the Google Calendar tab on the Options window.
        </li>
        <li>
          Improved the Agenda template to use calendar data source font sizes
          and to allow overriding the font sizes in the template settings.
        </li>
        <li>
          Improved importing from Exchange Server. If an account password has
          expired, a window will appear where the updated password can be
          entered. If multiple calendar data sources are associated with the
          account, they will also be automatically updated with the new
          password. This eliminates the need to remove and re-add all Exchange
          Server calendars when a password has expired.
        </li>
        <li>
          Corrected an error when importing shared room calendars from Exchange
          Server.
        </li>
        <li>
          Corrected an error message when attempting to import birthday
          calendars from Outlook.
        </li>
        <li>
          Corrected importing tasks from Outlook. Tasks that have a due date but
          no start date weren't properly importing.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2019.09 - August 7, 2019</h3>
      <ul>
        <li>
          Improved the size of the Month template output to fit better on a
          page. For best results, the "Condense unused rows" template setting
          should be unchecked.
        </li>
        <li>
          Improved the Agenda template with an option for including only
          weekends or weekdays in the output.
        </li>
        <li>
          Improved the formula for automatically inserting mini-months in the
          generated output. The formula now supports a parameter for specifying
          the starting month offset. See the{' '}
          <Link to="/Documentation/Insert-Mini-Calendar">documentation</Link>{' '}
          for more details.
        </li>
        <li>
          Corrected importing recurring events from iCalendar-based sources
          (such as iCloud, Yahoo!, Outlook.com, etc.). In some cases, an
          additional occurrence would appear after the recurring series ending
          date.
        </li>
        <li>
          Corrected the sort order of events in the generated output when the
          template setting to sort based on calendar data source has been
          selected. If calendar data sources were re-ordered, the updated order
          wasn't being applied to the output. The updated calendar data source
          order would also revert to the previous order after restarting.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2019.08 - May 24, 2019</h3>
      <ul>
        <li>
          Improved the Year Photo template to allow a starting month to be
          specified, instead of assuming January.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2019.07 - May 16, 2019</h3>
      <ul>
        <li>
          Improved the PowerPoint add-in to support generating the calendar as
          an editable Excel Worksheet Object, RTF, HTML, or an image.
          Previously, the generated calendar would always be inserted as an
          image and could not be edited. The calendar format can be selected
          near the bottom of the PrintableCal panel.
        </li>
        <li>Corrected minor issues related to license activation.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2019.06 - April 19, 2019</h3>
      <ul>
        <li>
          Corrected colors when importing from Google Calendar to exactly match
          the latest Google Calendar color palette.
        </li>
        <li>Corrected issues related to offline license activation.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2019.05 - March 29, 2019</h3>
      <ul>
        <li>
          Added the ability to include event and task descriptions in the Week
          Grid template (if space is available).
        </li>
        <li>
          Improved the color drop-down to include a "Random Color" option.
        </li>
        <li>
          Improved the display of bullet lists in descriptions to show indented
          bullet characters instead of dashes. Also improved numbered lists to
          be indented.
        </li>
        <li>
          Corrected errors connecting to Exchange Server if an incorrect
          password was previously entered or if the password has expired.
        </li>
        <li>
          Corrected the Add Microsoft Exchange Calendar window to show any
          errors that occur while attempting to connect, instead of just showing
          an empty calendar list.
        </li>
        <li>
          Corrected occurrence numbering of weekly events imported from Exchange
          Server if they occur on multiple days of the week.
        </li>
        <li>
          Corrected the "Restore to Original" menu command for templates that
          have been customized to override the default templates. The menu
          command wasn't getting removed after being clicked.
        </li>
        <li>
          Corrected the High Contrast Month Template to be properly centered on
          the page when generated in Excel.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 2019.04 - March 17, 2019</h3>
      <ul>
        <li>
          Corrected cases where the PrintableCal tab wouldn't appear on the
          ribbon menu after installation on some computers.
        </li>
        <li>
          Corrected the label showing the number of selected calendars after a
          calendar has been deleted.
        </li>
        <li>
          Corrected color-coding of events imported from Exchange Server when
          the option to use category colors has been unchecked.
        </li>
        <li>Corrected an error message when attempting to upgrade.</li>
        <li>Other minor corrections.</li>
      </ul>
      <h3>Version 2019.03 - March 11, 2019</h3>
      <ul>
        <li>Corrected compatibility with Office 2007.</li>
        <li>
          Corrected intermittent problems with word-wrapping of event and task
          titles.
        </li>
        <li>Other minor corrections.</li>
      </ul>
      <h3>Version 2019.02 - March 10, 2019</h3>
      <ul>
        <li>
          Corrected generating the Month template when the option to clip items
          in other months is unchecked.
        </li>
        <li>
          Corrected the "What's New" link on the update notification window to
          point to this page instead of the contact page.
        </li>
        <li>Other minor corrections.</li>
      </ul>
      <h3>Version 2019.01 - March 9, 2019</h3>
      <ul>
        <li>
          <strong>
            This is a major release. You can find more information about the
            changes in the{' '}
            <Link to="/Whats-New-Version-2019">
              PrintableCal 2019 release notes
            </Link>
          </strong>
          .
        </li>
        <li>
          The entire user interface has been refreshed and includes new features
          like a template preview carousel, the ability to search for templates
          by keyword, more intuitive customization of calendar fonts and colors,
          and much more.
        </li>
        <li>
          The PrintableCal installation now includes an add-in for PowerPoint.
          You can now generate your project schedules, Gantt Charts, a calendar
          of upcoming events, etc., directly to PowerPoint slides.
        </li>
        <li>
          A total of 10 new templates are now available in PrintableCal 2019,
          including a Year Photo calendar, a Day and Week template, a Day
          template showing time slots as columns, and more.
        </li>
        <li>
          Many new template settings have been added, such as an option to apply
          to a different font size depending on the template, an option to show
          event times in bold text to make them easier to see, an option to wrap
          the text in the "all-day" area of some templates, and much more.
        </li>
        <li>
          Templates can now automatically generate a color-coded legend. Events
          can be color-coded based on either their associated calendar data
          source or category. Categories can be imported or defined in the
          options.
        </li>
        <li>
          A feature has been added for inserting a "mini-month calendar" at the
          current edit location, or automatically as part of any template.
        </li>
        <li>
          The events and tasks that appear in the output can now be filtered
          based on category or whether they are marked as being all-day.
        </li>
        <li>
          Different combinations of selected calendar data sources can be saved
          as reusable presets.
        </li>
        <li>
          The text that appears in the output for each event and task can be
          customized. Want to see event times after their titles instead of
          before? You can do that now.
        </li>
        <li>
          You can now connect PrintableCal to additional data sources - Todoist,
          Excel files, and CSV files. You can also connect PrintableCal to any
          number of Google accounts. If you have multiple Google accounts and
          want to see all your events overlaid into a single calendar, this is
          possible now in PrintableCal 2019.
        </li>
        <li>And much, much more.</li>
      </ul>
      <p>
        <Image alt="" src={example_carousel} />
      </p>
      <h3>Version 1.10.5 - January 23, 2019</h3>
      <ul>
        <li>
          Corrected an error message when checking for updates on computers that
          connect to the internet through a proxy server.
        </li>
        <li>
          Corrected an intermittent crash when generating calendars in Word 2010
          or earlier.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.10.4 - November 27, 2018</h3>
      <ul>
        <li>
          Corrected intermittent "column width" errors that could appear on some
          computers when generating with certain templates.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.10.3 - November 20, 2018</h3>
      <ul>
        <li>
          Improved PrintableCal load time. PrintableCal will no longer affect
          how long it takes to start Word or Excel.
        </li>
        <li>
          Corrected intermittent problems with the PrintableCal panel not
          responding to clicks in Word and Excel. If you're still experiencing
          this problem and the previous releases didn't fix it, the problem
          should be corrected in this release.
        </li>
        <li>
          Corrected cases where unnecessary rows can sometimes appear within
          days in the Month template.
        </li>
        <li>
          Corrected the Month template to fill the page and be centered. Also
          improved the visual appearance of the Month template.
        </li>
        <li>
          Corrected all cases where event titles don't properly wrap in all
          templates that support word-wrapping.
        </li>
        <li>
          Corrected various user interface problems when display scaling is
          greater than 100%, such as labels overlapping text fields and windows
          not being sized large enough.
        </li>
        <li>
          Corrected the update checker to remember when the option to check for
          updates is unchecked and to not report an update is available when the
          latest version has already been installed.
        </li>
        <li>
          Corrected an error when attempting to add Google Calendar data
          sources.
        </li>
        <li>
          Corrected importing Facebook upcoming events and birthday calendars.
        </li>
        <li>
          Corrected importing from any iCalendar URL that requires Chrome or
          Firefox when accessed in a web browser.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.10.1 - June 23, 2018</h3>
      <ul>
        <li>
          Corrected the PrintableCal panel in Microsoft Excel to respond to
          clicks when running with the Windows 10 April 2018 update.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.10.0 - May 3, 2018</h3>
      <ul>
        <li>
          Corrected the PrintableCal panel in Microsoft Word to respond to
          clicks when running with the Windows 10 April 2018 update.
        </li>
        <li>Corrected license activation errors.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.9.8 - June 26, 2017</h3>
      <ul>
        <li>Corrected an error connecting Exchange Server.</li>
        <li>
          Corrected an error connecting to Yahoo! Calendar and other iCalendar
          URLs that require a secure connection (<span>TLS1.1, TLS1.2)</span>.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.9.7 - April 16, 2017</h3>
      <ul>
        <li>
          Improved the "Connect to Microsoft Exchange" window to provide an
          option to enter the server URL, in case the Exchange autodiscover
          service isn't enabled or fails to correctly resolve the server URL
          when attempting to connect.
        </li>
        <li>Corrected in-app purchasing.</li>
        <li>
          Corrected an error on some computers when attempting to generate using
          a Google Calendar data source.
        </li>
        <li>
          Corrected an error adding calendars from Outlook when there are
          multiple account profiles of the same name.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.9.6 - December 26, 2016</h3>
      <ul>
        <li>
          Added support for importing from any number of Outlook account
          profiles. If Outlook is installed locally and configured to sync with
          Outlook.com, iCloud, Exchange, Google Calendar, or any other external
          calendar, PrintableCal will now be able to import the data by going
          through Outlook. Note: this requires Outlook 2010 or later.
          PrintableCal will no longer work with Outlook 2007.
        </li>
        <li>
          Corrected all-day events to be grouped by calendar and sorted by
          title.
        </li>
        <li>
          Corrected the appearance of events that are color-coded based on
          category. The events weren't using the associated calendar font size
          and style.
        </li>
        <li>
          Corrected the color-coding of categories when events are imported from
          Outlook and Outlook has multiple account profiles defined.
        </li>
        <li>
          Corrected importing from VueMinder to include associated categories.
        </li>
        <li>
          Corrected the instructions for adding calendars from Outlook.com.
        </li>
        <li>
          Corrected the Month template when the option to clip events in other
          months is enabled. Multi-day events could still appear in the other
          months.
        </li>
        <li>Other corrections and improvements.</li>
      </ul>
      <h3>Version 1.9.5 - December 4, 2016</h3>
      <ul>
        <li>
          Corrected intermittent crashes and other error messages in Excel and
          Word after generating calendars.
        </li>
        <li>
          Corrected an installation "hash mismatch" error when the Visual Studio
          Tools for Office Runtime (VSTOR) isn't already installed prior to
          running the PrintableCal setup.
        </li>
        <li>
          Corrected the Quick Calendar button after exiting and restarting Excel
          and Word. Clicking the button would sometimes result in an error
          message.
        </li>
        <li>
          Corrected the Week Column template (and other similar templates) to
          show day names when the specified maximum number of columns per day is
          less than 7.
        </li>
        <li>
          Corrected a problem when all-day and multi-day events on the Week
          Column template (and other related templates) sometimes not appearing
          or being cut off before their end date when the specified maximum
          number of columns per day is less than 7.
        </li>
        <li>
          Corrected the 4-Days Per Page and Weekdays Only templates to properly
          limit the number of columns per day if the specified value is less
          than 7.
        </li>
        <li>
          Corrected an error that could sometimes appear when generating the
          Week Column and Day templates using a timeslot range that doesn't end
          at the top of the hour.
        </li>
        <li>
          Corrected an error message when attempting to generate a calendar
          after selecting a paper size that isn't supported by the default
          printer.
        </li>
        <li>
          Corrected the Month template to show end times on events that span
          multiple dates when the option to show end times is enabled.
        </li>
        <li>
          Corrected the High Contract Week Column template (and other similar
          templates) to prevent event backgrounds from being displayed over the
          border separating date columns.
        </li>
        <li>Other corrections and improvements.</li>
      </ul>
      <h3>Version 1.9.4 - February 21, 2016</h3>
      <ul>
        <li>
          Corrected the Week Column template to fill entire day widths when
          using a maximum of 6 columns or less for each day.
        </li>
        <li>
          Corrected all-day events when imported from Exchange Server. If the
          events were created in a different time-zone, they would span multiple
          dates in the generated output.
        </li>
        <li>
          Corrected a "Cannot implicitly convert type int to bool" error when
          generating certain custom calendar templates.
        </li>
        <li>
          Corrected an intermittent "Configuration system failed to initialize"
          error.
        </li>
        <li>
          Corrected the "Insert as picture" checkbox when generating calendars
          in Word. On some computers, the checkbox was clipped or not visible.
        </li>
        <li>
          Corrected intermittent cases where a license could become deactivated
          and would require re-activation.
        </li>
        <li>Other minor improvements and corrections.</li>
      </ul>
      <h3>Version 1.9.3 - September 26, 2015</h3>
      <ul>
        <li>
          Corrected a performance problem when the "Create Calendar" button is
          clicked in Excel 2016 or Word 2016.
        </li>
        <li>
          Corrected the order of all-day events to match the order of calendar
          data sources. Calendar data sources can be re-ordered using the
          up/down arrows next to the calendar names.
        </li>
        <li>
          Corrected the calendar data source list to not re-expand previously
          collapsed groups when a change is made to the calendar list, such as
          re-ordering a calendar or changing its color.
        </li>
        <li>
          Corrected the PrintableCal tab name on the ribbon in Excel 2016 and
          Word 2016 to not be all-CAPS.
        </li>
        <li>Other minor improvements and corrections.</li>
      </ul>
      <h3>Version 1.9.2 - September 7, 2015</h3>
      <ul>
        <li>
          Corrected event titles when importing rescheduled recurring
          appointments from Outlook. The imported titles would sometimes appear
          as "New Appointment".
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.9.1 - August 19, 2015</h3>
      <p></p>
      <ul>
        <li>
          Added an option to include the category colors of events that are
          imported from Exchange Server. Note, this feature requires Exchange
          Server 2010 or later. If you're using Exchange Server 2007 you'll need
          to disable this option via the Options button on the PrintableCal tab.
        </li>
        <li>
          Improved the performance of importing large calendars from Outlook.
        </li>
        <li>
          Corrected the output of certain templates to show overdue Google
          Tasks.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.9.0 - August 12, 2015</h3>
      <ul>
        <li>Added the ability to import tasks from Outlook.</li>
        <li>
          Added a Day template with a task list along the right side. Tasks can
          come from Outlook, VueMinder, Google Tasks, Toodledo, and other
          sources.
        </li>
        <li>
          Added an option to include item descriptions within item blocks of the
          Month and Multi-week templates. Previously, item descriptions could
          only be shown in tooltips and wouldn't print when using one of the
          Month or Multi-week templates.
        </li>
        <li>
          Improved the output in Word. Instead of the generated calendar being
          inserted as a regular Word table, the calendar will be inserted as a
          linked Excel object. This preserves the column widths and other
          formatting of the original template and makes the output look much
          better in Word.
        </li>
        <li>
          Improved the List template with an option to show overdue tasks.
        </li>
        <li>
          Corrected a "Hash value is incorrect" error when installing on
          computers that don't have the VSTO Runtime prerequisite installed yet.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.8.3 - August 2, 2015</h3>
      <ul>
        <li>
          Added the ability to move calendar data sources up or down in the
          list. Depending on the selected template, the order of calendars in
          the list will affect the order of events in the generated output.
        </li>
        <li>
          Improved the Day and Week Column templates with options to show event
          times and titles on separate lines, rather than always being on the
          same line as the event titles.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.8.2 - July 12, 2015</h3>
      <ul>
        <li>
          Improved the Day and Week template settings to include an option to
          bold the event time, title, and location text when descriptions are
          also shown.
        </li>
        <li>
          Improved the Day and Week template settings to include an option for
          showing a blank line before the description text within an event cell.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <p>
        <Image alt="" src={bold_titles} />
      </p>
      <h3>Version 1.8.1 - July 5, 2015</h3>
      <ul>
        <li>
          Added a "1 Day/Page Without Overlay" template. This template is
          similar to the standard "1 Day/Page" template, except it will show
          each calendar data source in a separate column, rather than overlaying
          all events into the same columns.
        </li>
        <li>
          Improved the Month, 2 Month, 6 Month, 12 Month, Multi-week, and Year
          templates with the option to choose any starting day of the week,
          rather than being limited to either Sunday or Monday.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <p>
        See below for an example of the output generated when using the new "1
        Day/Page Without Overlay" template. In this example, 3 calendars were
        included in the output. The output can support any number of calendar
        columns.
        <br />
        <Image alt="" src={example_1_day_no_overlay} />
      </p>
      <h3>Version 1.8.0 - June 16, 2015</h3>
      <ul>
        <li>
          Added the ability to select a color and font theme for the generated
          output. The "Default" theme will continue to use the colors and fonts
          specified in the selected template.
        </li>
        <li>
          ​Added a page size option when generating a calendar. The "Automatic"
          page size will use the default paper size specified in the template.
          Note, some templates are designed to fit best on Letter sized paper
          and may still need to be customized to fit better on the desired page
          size. Other templates will dynamically re-size to match whichever
          paper size is selected.
        </li>
        <li>
          Added automatic update checking. This feature is optional and can be
          turned off via the Check for Updates window, accessed from the About
          window.
        </li>
        <li>
          Added an option to color categorized events imported from a local
          Outlook calendar using the same category colors defined in Outlook.
        </li>
        <li>
          Added the ability to use the selected start date for determining the
          left-most day column in the Multi-week template, instead of only
          allowing Sunday or Monday.
        </li>
        <li>
          Corrected importing a custom template to show a message confirming the
          import was successful.
        </li>
        <li>
          Corrected problems related to removing a customized template that has
          the same name as one of the templates that was originally installed.
          The original template will be restored.
        </li>
        <li>
          Corrected intermittent messages stating that custom templates are
          invalid. These messages usually would appear after creating a new
          custom template or importing a custom template file.
        </li>
        <li>
          Corrected the Multiple Months as Columns and Multiple Months as Rows
          templates to not clear user-defined conditional formats.
        </li>
        <li>
          Corrected the Agenda template when no events occur within the
          specified date range. Instead of showing an example event occurring on
          Jan 1, the output will indicate no events occur on the specified
          dates.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <p>
        When generating a calendar, you can choose to use the default theme
        defined in the template, or you can pick a different theme from the list
        of installed theme files.
        <br />
        <br />
        <Image alt="" src={theme_list} />
      </p>
      <p>
        Below is an example using the Default Month Template along with the
        default theme.
        <br />
        <br />
        <Image alt="" src={month_default_theme} />
      </p>
      <p>
        Here's an example of how the same Default Month Template output appears
        when the "Main Event" theme is selected from the new drop-down list.
        Notice the different border color and date font. In the previous
        version, the default template would have needed to be customized in
        order to use a different theme.
        <br />
        <br />
        <Image alt="" src={month_main_event_theme} />
      </p>
      <p>
        The paper size can also be specified prior to generating a calendar,
        using a new "Paper size" drop-down list. Use the "Automatic" size to
        generate the template using the paper size it was originally designed
        for. Most templates will dynamically re-size to fit on other page sizes.
        <br />
        <br />
        <Image alt="" src={paper_size} />
      </p>
      <p>
        The "Week - Multiweek" template can now show any day of the week in the
        left-most column, instead of being limited to starting with Sunday or
        Monday.
        <br />
        <Image alt="" src={multiweek_first_day} />
      </p>
      <p>
        A new option has been added to import category colors for local Outlook
        events. The category colors will appear in the output instead of the
        calendar color, which will make the color-coding of PrintableCal's
        output match the color-coding seen in Outlook. To access this new
        option, click the Options button on the PrintableCal tab of the Office
        ribbon.
        <br />
        <br />
        <Image alt="" src={use_outlook_category_colors} />
      </p>
      <h3>Version 1.7.1 - May 13, 2015</h3>
      <ul>
        <li>
          Corrected duplicate events when generating with the multi-week
          template. Events would be duplicated if they span more than one day,
          into another week.
        </li>
      </ul>
      <h3>Version 1.7.0 - April 11, 2015</h3>
      <ul>
        <li>
          Added a customizable form letter template. This template is similar to
          "mail merge", except it populates form letters using data from your
          events.
        </li>
        <li>
          Improved the Microsoft Word output settings to allow specifying margin
          sizes in centimeters.
        </li>
        <li>
          Corrected an error message related to the Windows Clipboard that could
          sometimes appear when generating calendars in Microsoft Word.
        </li>
        <li>
          Corrected the Month template to show all imported events. In some
          cases, events were missing from the output.
        </li>
        <li>
          Corrected event times when importing from Yahoo! Calendar. Depending
          on the local time zone, the event times were sometimes incorrect.
        </li>
        <li>
          Corrected importing from Yahoo! Calendar to not include deleted
          occurrences of recurring events.
        </li>
        <li>Corrected the instructions for adding Yahoo! Calendars.</li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <p>
        An example form letter template is included with the PrintableCal
        installation. The template provides instructions, including the
        available field names that can be replaced with data from your events.
        Each event will have its data generated to one form letter. If using the
        PrintableCal Standard version or better, you can customize the template
        or create your own form letter templates.
        <br />
        <Image alt="" src={Form_Letter} />
      </p>
      <h3>Version 1.6.4 - March 14, 2015</h3>
      <ul>
        <li>
          Corrected adding calendars from VueMinder and Google Calendar when
          using Microsoft Word.
        </li>
        <li>
          Corrected duplicate events sometimes appearing in the output when
          importing from VueMinder.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.6.3 - March 6, 2015</h3>
      <ul>
        <li>
          Improved the installer no longer use ClickOnce. The new installer is
          built using the standard Microsoft Installer tech stack and will
          eliminate problems some people were encountering with the
          installation.
        </li>
        <li>
          Improved installation to work offline. Files will no longer need to be
          downloaded from the PrintableCal website during the installation.
        </li>
        <li>
          Improved the installer to show a "getting started" page after
          installation has completed.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.6.2 - February 27, 2015</h3>
      <ul>
        <li>
          Corrected a problem with additional white space sometimes appearing in
          dates in the Month template if word-wrapping is enabled.
        </li>
        <li>Other minor corrections and improvements.</li>
      </ul>
      <h3>Version 1.6.1 - February 16, 2015</h3>
      <ul>
        <li>
          Corrected an error seen on some computers when attempting to install
          or update.
        </li>
        <li>Other minor corrections and improvements</li>
      </ul>
      <h3>Version 1.6.0 - January 31, 2015</h3>
      <ul>
        <li>
          Added high-contrast versions of most templates. The high-contrast
          templates feature larger fonts and thicker borders for easier viewing.
        </li>
        <li>
          Improved the output of templates that are configured to fit on a
          single page. Those templates will now be auto-scaled to best fit the
          paper size that is specified for the template.
        </li>
        <li>
          Corrected the option to automatically update check for updates.
          Previously, PrintableCal would still periodically check for updates
          even if this option was disabled by the user.
        </li>
        <li>
          Corrected timed events in the multi-week template to include
          background colors, depending on whether this option is selected.
        </li>
        <li>Other minor improvements and corrections.</li>
      </ul>
      <p>
        Here's an example that was generated using one of the new high-contrast
        templates:
        <Image alt="" src={High_Contrast_Month_Template} />
      </p>
      <h3>Version 1.5.4 - January 23, 2015</h3>
      <ul>
        <li>
          Corrected a problem with events appearing in the output if they belong
          to a recurring series that ended in the past. This bug affected
          Outlook and all iCalendar-based calendar data sources.
        </li>
      </ul>
      <h3>Version 1.5.3 - January 2, 2015</h3>
      <ul>
        <li>
          Corrected an error message when attempting to add a local Outlook
          calendar.
        </li>
      </ul>
      <h3>Version 1.5.2 - December 17, 2014</h3>
      <ul>
        <li>
          Added an option to the Month templates for starting in the current
          month, next month, or a selected month. This will enable the 1-step
          Quick Calendar feature to dynamically update to use the current or
          next month.
        </li>
        <li>
          Improved the Week Column template to start on any date, rather than
          the first day (Sunday or Monday) of the week.
        </li>
        <li>Corrected importing recurring events from Outlook.</li>
        <li>
          Corrected importing changed occurrences of recurring events from
          iCalendar (ics) files and URLs. In some cases, duplicates would
          appear.
        </li>
        <li>Other minor improvements and corrections.</li>
      </ul>
      <p>
        <Image alt="" src={Start_Output_Month} />
      </p>
      <h3>Version 1.5.1 - November 20, 2014</h3>
      <ul>
        <li>
          Added a Week Grid template that uses portrait-mode page layout,
          allowing more events to fit (up to 21 per day).
        </li>
        <li>
          Added options to toggle whether event ending times and locations
          should be included in the Agenda template output.
        </li>
        <li>
          Corrected event titles sometimes appearing blank when the "Show
          descriptions" option is disabled.
        </li>
        <li>
          Corrected the Agenda template to not show event ending times if equal
          to starting times.
        </li>
        <li>Other minor improvements and corrections.</li>
      </ul>
      <p>
        <Image alt="" src={Agenda_Template_Show_Ending_Times_And_Locations} />
      </p>
      <h3>Version 1.5.0 - November 20, 2014</h3>
      <ul>
        <li>
          Added a "Weekdays Only" template. This template style is similar to
          the "Week Columns" template, except it excludes Saturday and Sunday.
        </li>
        <li>
          Improved the Week Columns and 4 Days/Page templates to remove the
          empty left-most column of each date.
        </li>
        <li>
          Improved the day borders in the default Week Columns and 4 Days/Page
          templates to be wider and easier to see.
        </li>
        <li>
          Improved the 1 Day/Page, 4 Days/Page, and Week Column templates to
          support up to 60 concurrent events. Previously, only 20 concurrent
          events could be shown in the output.
        </li>
        <li>
          Corrected problems with concurrent events in the 1 Day/page, 4
          Days/Page, and Week Column templates sometimes missing titles or not
          having their shapes properly initialized. These problems occurred
          depending on how various events overlap.
        </li>
        <li>
          Corrected the 1 Day/Page template when a 5 or 10 minute timeslot
          interval is selected. Previously, this would result in no events
          appearing in the output.
        </li>
        <li>
          Corrected events that extend beyond the last timeslot specified in the
          template settings. Rather than the event shape continuing past the
          range limit, the bottom border will be dashed to indicate the event
          continues beyond the last displayed timeslot. This correction is in
          the 1 Day/Page, 4 Days/Page, and Week Column templates.
        </li>
        <li>
          Corrected the first day of the week shown in the Week Column template
          when the Quick Calendar button is used to generate the week calendar.
        </li>
        <li>Other minor improvements and corrections.</li>
      </ul>
      <p>
        <Image alt="" src={Weekday_Template} />
      </p>
      <h3>Version 1.4.5 - October 4, 2014</h3>
      <ul>
        <li>
          Added a Month Template option to overflow events to an additional page
          if they won't fit within the specified maximum number of rows in a
          date. The month will be repeated showing the events that didn't fit on
          the previous page. An option is also provided to show a "+ More"
          indicator instead of overflowing onto additional pages.
        </li>
        <li>
          Corrected the "+ More" indicator to always be in the last row of a
          date if there are more events than rows, and if the option to overflow
          onto additional pages hasn't been selected. Sometimes the indicator
          would be in the 2nd to last row, or one row past the last row in a
          date.
        </li>
        <li>Other minor improvements and corrections.</li>
      </ul>
      <p>
        <Image alt="" src={Month_Overflow_Option} />
      </p>
      <h3>Version 1.4.3 - September 26, 2014</h3>
      <ul>
        <li>
          Added the ability to mark templates as "favorites". Favorite templates
          will be listed above all other templates. To mark a template as a
          favorite, click the gear button next to the template name and select
          "Add to Favorites" from the drop-down.
        </li>
        <li>Improved the List template to support up to 273 years.</li>
        <li>
          Corrected the title of the 6-Month Template to show the year range
          (example: 2014-15).
        </li>
        <li>
          Corrected month titles in the 6-Month Template to include the year if
          all 6 months aren't in the same year.
        </li>
        <li>Other minor improvements and corrections.</li>
      </ul>
      <p>
        <Image alt="" src={Add_To_Favorites} />
      </p>
      <h3>Version 1.4.0 - August 29, 2014</h3>
      <ul>
        <li>
          Added a "List" template which can include items that don't have
          associated dates, such as tasks imported from Google Tasks or
          VueMinder.
        </li>
        <li>
          Added a multi-month template, where months are arranged as columns and
          days as rows.
        </li>
        <li>
          Added a multi-month template, where months are arranged as rows and
          days as columns. The new multi-month templates can fit a whole year on
          a single page while still showing event details.
        </li>
        <li>
          Corrected the Week Column template when printing multiple weeks
          containing events that span multiple days. Those events were sometimes
          incorrectly duplicated on subsequent weeks.
        </li>
        <li>Other minor improvements and corrections.</li>
      </ul>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Whats-New"
      commentsId="whats_new"
      title="What's New - Release Notes"
      description="PrintableCal continues to be improved with new calendar templates, support for additional calendar data sources, and many other new features."
      keywords="PrintableCal, release notes, version history"
      content={content}
    />
  );
};

export default Documentation;
